$(() => {

    $('header .mobile-nav-toggle, header .nav-toggle').on('click touchstart', function () {
        $(this).toggleClass('open');
        $('#main-nav').toggleClass('open');
        return false;
    });

});


